import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { compose, lifecycle } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { CHANGE_DELIVERY_DATE } from "../../../../constants/analytics";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { Fields } from "../../../../constants/forms";
import { DELIVERY_DATE, REDELIVER } from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { checkMinDate, onChangeParcelDateSubmitREI } from "../../actions";
import EditPanel from "../../components/EditPanel";
import { hadleInputFocus, loadActionDates, withParcelEdit } from "../../hocs";

const RedeliverToSameAddress = ({
  handleSubmit,
  onSubmit,
  error,
  getDatesRange,
  filterDate,
  onClickDate,
  preventDatePickerBlur,
  setIsDatePickerOpen,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={REDELIVER}>
      <Row>
        <Col className="col-6">
          <Field
            name={Fields.DELIVERY_DATE}
            component={FormControl.DatePicker}
            label={DELIVERY_DATE}
            // NOTE: getDatesRange is required to forbid change month by clicking header arrows and set proper date if date was changed
            getDatesRange={getDatesRange}
            filterDate={filterDate}
            dateFormat={DEFAULT_DATE_FORMAT}
            autoFocus={false}
            onDatePickerChange={setIsDatePickerOpen}
            onBlur={(...props) => {
              preventDatePickerBlur(props[0]);
              onClickDate(props[2]);
            }}
          />
        </Col>
      </Row>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        {...rest}
      />
    </CardWithTitle>
  </Col>
);

RedeliverToSameAddress.defaultProps = {
  onSubmit: () => null,
};

RedeliverToSameAddress.propTypes = {
  getDatesRange: PropTypes.func,
  filterDate: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  loadActionDates,
  withParcelEdit({
    onSubmit: (parcelCode, values) => {
      return onChangeParcelDateSubmitREI(parcelCode, {
        ...values,
      });
    },
    validate: deliveryEditValidators.changeDeliveryDateValidator,
  }),
  withTrack(CHANGE_DELIVERY_DATE.LOAD),
  withTrackProps({
    onSubmit: CHANGE_DELIVERY_DATE.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.DELIVERY_DATE:
          return CHANGE_DELIVERY_DATE.CLICK_DROP_DOWN_LIST;
        case Fields.INSTRUCTIONS:
          return CHANGE_DELIVERY_DATE.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return CHANGE_DELIVERY_DATE.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return CHANGE_DELIVERY_DATE.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return CHANGE_DELIVERY_DATE.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
    onClickDate: CHANGE_DELIVERY_DATE.CLICK_DATE,
  }),
  hadleInputFocus,
  lifecycle({
    componentDidUpdate() {
      this.props.dispatch(checkMinDate());
    },
  })
)(RedeliverToSameAddress);
